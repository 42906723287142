<template>
  <div id="app">
    <title v-if="this.$route.query.key">{{ gongsi.enterprise_name }} Ai智能助手</title>
    <title v-else>数字人AI</title>
    <router-view></router-view>
  </div>
</template>

<script>
import { HemisphereLightProbe } from 'three';


export default {
  
  // components: {

  // },
  data(){
    return{
      msg:'',
      key:'',
      gongsi:''
    }
  },
  mounted(){
    if (this._isMobile()) {
      console.log('app'); 
      this.$router.push('/digtai?key=' + this.$route.query.key)
    } else {
      this.$router.push('/index?key=' + this.$route.query.key)
      console.log('pc');
    }
    this.logoName(this.$route.query.key)

  },
  methods:{
    _isMobile() {
	 let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
	 return flag;
    },
    	//logo和公司名
		logoName(i) {
			this.postRequest(this.constant.loadurl.get_application_setting, this.qs.stringify({
				key: i
			})).then((res) => {
				if (res.data.code == 0) {
					this.gongsi = res.data.data
          var link = document.querySelector('link[rel*="icon"]')   
          link.href=res.data.data.logo
				}
			})
		},
  }

}
</script>

<style>
#app {
  width: 100%;
  margin:0;
  padding: 0;
  /* overflow: hidden; */
  /* text-align: center; */
}
</style>
