<!-- 手机端-->
<template>
	<div class="content" :style="{ backgroundImage: 'url(' + bgtest + ')' }" :class="[gongsi.window_position == 1 ? 'boxOne' : '',
	gongsi.window_position == 2 ? 'boxTwo' : '',
	gongsi.window_position == 3 ? 'boxThree' : '',
	gongsi.window_position == 4 ? 'boxFour' : '',
	gongsi.window_size == 1 ? 'sizeOne' : '',
	gongsi.window_size == 2 ? 'sizeTwo' : '',
	]">
		<div class="opacity_pup" v-if="model_show">
			<div @click="AutoMp3" ref="Btn" class="loadBtn">
				<div class="names">欢迎使用数字人系统</div>
				<div class="btnTy">立即体验</div>
			</div>
		</div>
		<div class="logo_name" v-if="gongsi.logo && gongsi.enterprise_name">
			<img :src="gongsi.logo" alt="" width="40" height="40" class="src">
			<div>{{ gongsi.enterprise_name }}</div>
		</div>
		<renwu v-if="gongsi" :environment="gongsi.environment" :modelId="gongsi.digital.id" :seat="gongsi.seat"
			:parallel="gongsi.parallel" :modelURL="gongsi.digital.model_url" @handleClick="handleClick1"></renwu>
		<div class="lyOrClear_App">
			<div class="clear" @click="Clear_screen">
				<div class="imgDiv"><img src="../../static/imgs/qingping.png" alt="" srcset=""></div>
				<div class="tishi">清屏</div>
			</div>
			<div class="Ly" @click="liuyan">
				<div class="imgDiv"><img src="../../static/imgs/liuyan.png" alt=""> </div>
				<div class="tishi2">留言</div>
			</div>
		</div>
		<div class="pupBox" v-if="serverStr.type == 1">
			<div class="video_box">
				<video :src="serverStr.answer || mp4Url" controls autoplay class="AVideo"></video>
				<i class="el-icon-circle-close icon_closeH5" style="color:#ffffff;font-size: 2rem; margin-top: .625rem;"
					@click="del"></i>

			</div>
		</div>
		<div class="container_a">
			<div class='chat_users' v-if='recognizedText || result'>
				<div>
					{{ recognizedText || result }}
				</div>
			</div>
			<div class="huifu" v-if="state == '1'">
				<img :src="gongsi.digital.head_image" alt="">
				<div class='serve_text_a'>{{ serverStr }}</div>
			</div>
			<div class="huifu addList" v-if="serverStr.type == 0">
				<img :src="gongsi.digital.head_image" alt="">
				<div class='serve_text_a' v-if="serverStr.answer !=null">{{ serverStr.answer }}</div>
				
			</div>
			<div class='lookDiv' v-if='text_img.length != 0 && serverStr.type == 0' @click="prevImage">
				<i class='el-icon-picture-outline'></i>
				查看图片
			</div>
			<div class="huifu" v-if="serverStr.type == 2">
				<img :src="gongsi.digital.head_image" alt="">
				<div class='serve_text_a active' @click="link_href(serverStr.answer)">
					点击跳转
				</div>
			</div>
			<div class='huifu ' v-if="serverStr.type == 3">
				<img :src="gongsi.digital.head_image" alt="">
				<div class='serve_text_a lookDiv text_look' @click="open_maxImg(serverStr.answer)">
					<i class='el-icon-picture-outline'></i>
					查看图片
				</div>
			</div>
			<div class="huifu addList" v-if="serverStr.type == 4">
				<img :src="gongsi.digital.head_image" alt="">
				<div class='serve_text_a type_four' v-if="serverStr.answer !=null" v-html="serverStr.answer.replaceAll('。',' ')"></div> <!---->
				
			</div>
			<div class="huifu" v-if="serverStr.length > 0 && this.state == 0">
				<img :src="gongsi.digital.head_image" alt="">
				<div class='serve_text_a'>
					<div class="title">你是否想要了解以下问题：</div>
					<div v-for="(item, index) in serverStr" @click="question_fun(item)" class="msg">
						<div>{{ item.question }}</div>
					</div>
				</div>
			</div>
			<div class="dibu bottom_model">
				<div class='button_menu'>
					<div id="apps">
						<div class="voice-input-button-wrapper">
							<voice-input-button appId="5e7318f9" apiKey="7f067847197d323ac97da9fa173d461a"
								apiSecret="267eb2c7702ddabc3c419bd6931c7f04" v-model="result" @record="showResult"
								@record-start="recordStart" @record-stop="recordStop" @record-blank="recordNoResult"
								@record-failed="recordFailed" @record-ready="recordReady" @record-complete="recordComplete"
								interactiveMode="press" color="#3f8ceb" tipPosition="top" tipTextColor="#ffffff"
								:vad_eos="300">
								<template slot="no-speak">没听清您说的什么</template>
								<template slot="recording">好，请讲</template>
								<template slot="wait">请稍后</template>
							</voice-input-button>

						</div>
					</div>
				</div>

				<div v-if="qylist.length <= 4" class="line_twoBox">
					<div v-for='(item, index) in qylist' @click="Listitem(item)" style="cursor:pointer;">{{ item.question }}
					</div>
				</div>
				<div class='List' v-else="qylist.length < 4">
					<div v-for='(item, index) in qylist' @click="Listitem(item)" style="cursor:pointer;">{{ item.question }}
					</div>
				</div>
				<div class="bottom_border"></div>
				<div class='edit_text'>
					<textarea placeholder="您想咨询什么？" v-model="chatStr">  </textarea>
				</div>
				<div class='send_btn'>
					<div @click="send" class="sendA">发送</div>
				</div>
			</div>
			<div class="YsxY">
				<div>该产品由嗨媒体AI支持，内容仅供参考，</div>
				<div class="line_two">
					不代表本公司的态度或观点 请遵守<div class="zc" @click="goPageZc">《隐私政策》</div>和<div class="xy" @click="goPageXy">《隐私协议》
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import axios from "axios"
import console, { log } from "console"
import { isVideoURL } from '../utils/isVideoURL.js'
import voiceInputButton from 'voice-input-button2'
import ttsRecorder from "../assets/audio"
import renwu from "../components/renwu.vue"
import { type } from "os"
// const tts = new ttsRecorder() // 语音合成
export default {
	components: {
		voiceInputButton,
		renwu
	},
	data() {
		return {
			title: 'Hello',
			qylist: [{ question: '公司简介' }, { question: '企业展厅' }, { question: '企业视频' }],
			list: [{ question: '公司简介' }, { question: '企业展厅' }, { question: '企业视频' }],
			result: '', // 转换完的文字
			recognizedText: '',
			chatStr: '', // 客户端问题
			serverStr: '', // 服务端问题
			state: '3',//区分百度1或者自定0
			mp4Show: false,
			mp4Url: '',
			key: '', // 不同企业 对应不同的标识
			gongsi: '',
			timer: null,
			tts: new ttsRecorder(),
			pupImageShow: false,
			bgtest: 'https://chatoss.zhyell.com/2023-06-27_1687849572_649a8a641e1b6.png',
			timer: null,
			showMessage: false,
			model_show: false,
			messageArr: ['您好有什么需要帮忙的吗？你可以说，讲个故事，来一张图片，说个笑话', '你好，有什么需要帮忙的吗', '我是你的智能助手，有什么需要随时问我就好了。'],
			text_img: [],// 图文
		}
	},
	// 监听用户多久操作
	watch: {
		showMessage: {
			handler(n) {
				if (n == true) {
					var t = this
					var msgStr = t.messageArr[Math.floor(Math.random() * t.messageArr.length)]
					t.play(msgStr)
				}
			},
			deep: true
		},

	},
	onLoad() {
		document.body.style.overflow = 'hidden';
	},

	mounted() {
		if (this.$route.query.key) {
			this.key = this.$route.query.key;
		} else {
			this.key = ''
		}
		this.startTimer()
		this.logoName(this.key)
	},

	methods: {
		AutoMp3() {
			var msgStr = this.messageArr[Math.floor(Math.random() * this.messageArr.length)]
			this.play(msgStr)
			this.model_show = false

		},
		// 模型
		handleClick1(res) {
			if (res == true) {
				this.model_show = true
			}
		},
		//发送
		send() {
			this.recognizedText = this.chatStr
			this.serveMsg(this.chatStr, this.key)
			this.chatStr = ''
			this.serverStr = ' '
			this.text_img = []
		},
		// 选择按钮
		Listitem(e) {
			this.tts.stop()
			this.recognizedText = ''
			this.chatStr = ' '
			this.serverStr = ' '
			this.text_img = ''
			this.recognizedText = e.question
			this.serveMsg(e.question, this.key)
		},
		//跳转
		link_href(url) {
			const textRegex = /<[^>]+>/g;
			const matchedText = url.replace(textRegex, '        ');
			window.open(matchedText)
			// window.open(url)
		},
		prevImage() {
			// this.prevShow = true;
			this.$hevueImgPreview({
				multiple: true,
				nowImgIndex: 0,
				imgList: this.text_img
			})
		},
		open_maxImg(res) {
			this.$hevueImgPreview(res)
		},
		//删除del
		del() {
			this.recognizedText = ''
			this.chatStr = ''
			this.serverStr = ''
		},
		// 选择问题之后
		question_fun(res) {
			this.recognizedText = res.question
			this.serveMsg(res.question, this.key)
		},
		toggleFullscreen() {
			this.isFullscreen = !this.isFullscreen;
		},
		//服务端返回的问题
		serveMsg(Text, key) {
			clearTimeout(this.timer);
			this.showMessage = false;
			this.startTimer();
			this.question_num(Text, key)
			this.postRequest(this.constant.loadurl.get_answer, this.qs.stringify({
				question: Text,
				key: key
			})).then((res) => {
				// 请求成功的处理逻辑
				this.serverStr = res.data.data
				if (res.data.data.images != undefined && res.data.data.images.length != 0 && res.data.data.type == 0 ) {
					this.text_img = res.data.data.images
				if(res.data.data.answer != null){
					this.play(res.data.data.answer + '请点击查看图片')
				}else{
					this.play('请点击查看图片')
				}
					return;
				}
				if (res.data.code == '0' && Array.isArray(res.data.data) != true) {
					this.state = '0'
					if (res.data.data.type == 0) {
						this.play(res.data.data.answer)
					}else if(res.data.data.type == 2){
						this.play('请点击跳转至'+ Text)
					}else if(res.data.data.type == 3){
						this.play('请点击查看图片')
					}else if(res.data.data.type == 4){
						const richText = res.data.data.answer;
						const textRegex = /<[^>]+>/g;
						const matchedText = richText.replace(textRegex, '        ');
						this.play(matchedText)
					}
				} else if (res.data.data.length > 0 && Array.isArray(res.data.data)) {
					this.state = '0'
					let msg = res.data.data.map((item, index) => {
						return item.question
					})
					this.play(`你是否想要了解以下问题：${msg.toString()}`)
				} else if (res.data.code == '101' || res.data.code == '102') {
					this.state = '1'
					this.play(res.data.data)
				} else {
					this.play(res.data.msg || res.data.message)
					this.serverStr = res.data.msg || res.data.message
				}
			});
		},
		// 登录之后 模糊问题需走这个接口
		list_qfyestion(res) {
			// get_answer_info?id=
			this.postRequest(this.constant.loadurl.get_answer_info, this.qs.stringify({
				id: res.id,
				key: this.key
			})).then(res => {
				if (res.data.code == 0) {
					this.serverStr = res.data.data
					this.play(res.data.data)
				}
			})
		},
		// 记录问题次数
		question_num(q_num, k) {
			this.postRequest(this.constant.loadurl.set_answer, this.qs.stringify({
				question: q_num,
				key: k
			})).then((res) => {
				console.log('处理成功')
				// 请求成功的处理逻辑
			});
		},
		//logo和公司名
		logoName(i) {
			this.postRequest(this.constant.loadurl.get_application_setting, this.qs.stringify({
				key: i
			})).then((res) => {
				if (res.data.code == 0) {
					this.gongsi = res.data.data
					if (res.data.data.questionBank) {
						this.qylist = res.data.data.questionBank
					} else {
						this.qylist = this.list;
					}

					if (res.data.data.digital_background && this.key) {
						this.bgtest = this.gongsi.digital_background.background_img
					} else {
						this.bgtest = 'https://chatoss.zhyell.com/2023-06-27_1687849572_649a8a641e1b6.png'

					}
				}
			})
		},
		goPageZc() {
			this.$router.push({
				name: "zc"

			})
		},
		goPageXy() {
			this.$router.push({
				name: "xy"
			})
		},
		Clear_screen() {
			this.tts.stop()
			this.recognizedText = ''
			this.chatStr = ''
			this.serverStr = ''
			this.result = ''
		},
		liuyan() {
			this.$router.push('/Ly?key=' + this.$route.query.key)
		},
		startTimer() {
			this.lastClickTime = Date.now();
			this.timer = setInterval(() => {
				this.showMessage = true;
			}, 10 * 60 * 1000); // 5分钟
		},
		//语音识别
		play(txt) {
			var voiceR = 'xiaoyan'
			if (this.gongsi.voice) {
				voiceR = this.gongsi.voice.voice_name
			}
			this.tts.setParams({
				text: txt, // 文本
				speed: 35, // 语速	
				voice: 50, // 音量
				rdn: '2',
				voiceName: voiceR  // 
			});
			this.tts.start()
		},
		// 语音听写
		recordReady() {
			console.info('按钮就绪!')
		},
		recordStart() {
			console.info('录音开始')
		},
		showResult(text) {
			console.info('收到识别结果：', text)

		},
		recordStop(text) {
			console.info('录音结束')
		},
		recordNoResult() {
			console.info('没有录到什么，请重试')
		},
		recordComplete(text) {
			console.info('识别完成! 最终结果：', text)
			this.serveMsg(text, this.key)
		},
		recordFailed(error) {
			console.info('识别失败，错误栈：', error)
		},
	}
}
</script>

<style lang="scss">
html,
body {
	margin: 0;
	padding: 0;
	// overflow: hidden;
}

/*
不全屏 ：height: 80vh;width: 90%;
*/
.content_tuqiang {
	height: 100vh;
	width: 100%;
	// background-image: url('../../static/imgs/tuqiang.png');
	background-size: 100% 100%;
	background-repeat: no-repeat;
	position: relative;
	overflow: hidden;
}


.opacity_pup {
	position: absolute;
	width: 100%;
	height: 100vh;
	z-index: 999;
	background: rgba($color: #000000, $alpha: .5);

	.loadBtn {
		position: absolute;
		width: 18.75rem;
		text-align: center;
		top: 50%;
		left: 50%;
		display: flex;
		align-items: center;
		flex-direction: column;
		border-radius: .625rem;
		transform: translate(-50%, -50%);

		background-color: hsla(0, 0%, 100%, .8);

		.names {
			padding: .625rem .9375rem;
			color: #606266;
			font-size: .875rem;
			margin-top: .625rem;
		}

		.btnTy {
			width: 9.375rem;
			text-align: center;
			margin-top: 3.125rem;
			margin-bottom: .625rem;
			padding: .3125rem 0rem;
			color: #fffFFF;
			background-color: #3662ec;
			border-radius: 1.25rem;

		}
	}
}


.content {
	height: 100vh;
	width: 100%;
	// background: url('../../static/imgs/bj.png');
	background-size: 100% 100%;
	background-repeat: no-repeat;
	position: relative;
	overflow: hidden;
}

/*
窗口位置:1:中下 2:右下 3:居中 4:右中
*/
.boxOne {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	bottom: 0;
}

.boxTwo {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	bottom: 0;
}

.boxThree {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.boxFour {
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
}

/*
窗口大小:1窗口 2全屏
*/
.sizeOne {
	height: 80vh;
	width: 90%;
}

.sizeTwo {
	height: 100vh;
	width: 100%;

}

.logo_name {
	display: flex;
	align-items: center;
	justify-content: center;
	color: #ffffff;
	padding-top: 20px;
	font-size: 14px;

	img {
		margin-right: 4.8px;
		border-radius: 50%;
	}
}

.lyOrClear_App {
	z-index: 982123;
	position: absolute;
	right: 1.25rem;
	top: 6.25rem;
	text-align: center;

	.clear,
	.Ly {
		display: flex;
		flex-direction: column;
		color: #ffffff;
		font-size: .875rem;

	}

	.clear {

		img {
			height: 2.375rem;
			width: 2.375rem;
		}
	}

	.Ly {
		margin-top: 1.25rem;

		img {
			height: 2.375rem;
			width: 2.375rem;
		}
	}
}

.login {
	margin-right: 1.25rem;
	padding-top: 1.25rem;
	text-align: right;
	color: #ffffff;
}

.pupBox {
	position: absolute;
	height: 100%;
	width: 100%;
	z-index: 982124 !important;
	background-color: rgba(24, 24, 24, 0.5);
	display: flex;
	justify-content: center;
	top: 0;

	.video_box {
		height: 25rem;
		width: 21.875rem;

		.AVideo {
			margin-top: 100px;
			width: 100%;
			border-radius: 10px;
			object-fit: fill;
		}

		.icon_closeH5 {
			position: absolute;
			z-index: 2001;
			left: 50%;
			transform: translateX(-50%);
			top: 38%;
			color: #fffFFF;
		}
	}

}

.chat_users {
	display: flex;
	// position: absolute;
	color: #FFFFFF;
	justify-content: flex-end;
	text-align: right;

	div {
		max-height: 200px;
		font-size: 14px;
		padding: .4rem .7rem;
		background: #41A6EC;
		border-radius: 29px 0px 32px 29px;
		overflow: auto;
		text-align: left;
	}
}

.addList {
	position: relative;
	margin-bottom: 10px !important;
}

.text_look {
	color: #ffffff !important;
}

.lookDiv {
	height: 1.35rem;
	width: 5.4rem;
	color: #ffffff;
	font-size: 14px;
	margin-left: 60px;
	margin-bottom: 30px;
	background-image: url('../../static/imgs/kuang1.png');
	background-repeat: no-repeat;
	background-size: 100% 100%;
	text-align: center;
	line-height: 1.35rem;
}

.huifu {
	display: flex;
	margin-top: 10px;
	margin-bottom: 20px;

	.serve_text_a {
		max-height: 160px;
		max-width: 272px;
		padding: .5rem;
		font-size: 14px;
		background-color: rgba($color: #0c0c0c, $alpha: 0.8);
		border-radius: 0rem 16px 16px 16px;
		text-align: justify;
		overflow-y: auto;
		color: #42A9EC;
		margin-left: .9375rem;

		img {
			height: 12.5rem !important;
			width: 12.5rem !important;
		}
		p{
			margin: 0;
		}
	}
	.type_four{
		p{

			color:skyblue;
		}
		a{
			color:skyblue;
		}
	}

	.serve_textAn {
		max-height: 100px;
		max-width: 272px;
		padding: .5rem;
		font-size: 14px;
		background-color: rgba($color: #0c0c0c, $alpha: 0.8);
		border-radius: 0rem 16px 16px 16px;
		text-align: justify;
		overflow-y: auto;
		color: #fffFFF;
		margin-left: .9375rem;

		img {
			height: 9.375rem !important;
			width: 9.375rem !important;
		}
	}

	img {
		height: 41px;
		width: 41px;
		// border-radius: 50%;
	}
}

.active {
	color: rgb(76, 114, 114);
	text-decoration-line: underline;
}

.msg {
	font-size: 1rem;

	div {
		font-size: .875rem;

	}
}

// 这是底部
.container_a {
	width: 95% !important;
	position: absolute;
	bottom: 1%;
	left: 50%;
	transform: translateX(-50%);

	.dibu {
		width: 100%;
		background-size: 100% 100%;
		background-position: left;
		background-repeat: no-repeat;
		background-image: url('../../static/imgs/bj-k.png');
	}
	.bottom_model{
		margin-top: 30px;
	}
	.YsxY {
		font-size: .75rem;
		color: #5B5B5B;
		text-align: center;

		.line_two {
			display: flex;
			justify-content: center;
		}
	}

	.button_menu {
		position: relative;
		top: -1.2rem;

		#apps {
			display: flex;
			justify-content: center;
			text-align: center;
			// margin-top: .5rem;
		}
	}

	.List {
		width: 95%;
		display: flex;
		// padding-bottom: .425rem;
		margin: .3125rem auto;
		justify-content: space-between;
		flex-wrap: wrap;

		div {
			color: #333333;
			font-size: .875rem;
			padding: .35rem 16px;
			margin: 0 .1875rem;
			border-radius: 1.625rem;
			background: #CECECE;
			white-space: nowrap;
			margin-top: 10px;
		}

	}

	.line_twoBox {
		width: 95% !important;
		display: flex;
		// padding-bottom: .425rem;
		margin: .3125rem auto;
		justify-content: space-between;
		flex-wrap: nowrap;

		div {
			color: #333333;
			font-size: .875rem;
			padding: .35rem 8px;
			border-radius: 1.625rem;
			background: #CECECE;
			white-space: nowrap;
			margin-top: 10px;
		}
	}

	.bottom_border {
		height: .0625rem;
		width: 93% !important;
		background: #5C5D5F;
		opacity: 0.2;
		margin: 0 auto;
		margin-bottom: .625rem;
	}

	.edit_text {
		width: 90%;
		height: 3.125rem;
		overflow: hidden;
		margin: 0 auto;
		// padding: 0rem .4375rem;

		textarea {
			width: 100%;
			height: 100%;
			white-space: pre-wrap;
			font-size: .875rem;
			resize: none;
			color: #0c0c0c !important;
			/* 重置所有边框样式 */
			border: none;
			outline: none;
			padding: 0;
			background: none;

		}
	}

	.send_btn {
		display: flex;
		justify-content: flex-end;
		align-items: center;



		.sendA {
			padding: .5rem 1.4375rem;
		}

		.sendA {
			display: flex;
			justify-content: center;
			align-items: center;

			color: #ffffff;
			background-color: #3E7FEB !important;
			margin-right: .8125rem !important;
			margin-bottom: 10px;
			font-size: 14px;
			border-radius: 1.875rem;
		}
	}

	.voice-input-button-wrapper {
		width: 4rem;
		height: 4rem;
		background-color: #fffFFF;
		border-radius: 50%;
	}
}

/* 定义滚动条样式 */
::-webkit-scrollbar {
	width: 2px;
	height: 2px;
	background-color: rgba(240, 240, 240, 1);
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 0px rgba(240, 240, 240, .5);
	border-radius: 10px;
	background-color: rgba(240, 240, 240, .5);
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
	border-radius: 10px;
	box-shadow: inset 0 0 0px rgba(116, 110, 110, 0.5);
	background-color: rgba(116, 110, 110, .5);
}</style> 
